.App {
  font-family: sans-serif;
  text-align: center;
}
.myStyle{
  width: 650px;
  margin-top: 50px;
}
@font-face {
  font-family: "Hacen Liner Print-out" ;
  src: url(./components/fonts/HacenLinerPrint-out.ttf);
}
@font-face {
  font-family: "Hacen Liner Screen Bd" ;
  src: url(./components/fonts/HacenLinerScreenBd.ttf);
}
@font-face {
  font-family: "Hacen Liner Screen" ;
  src: url(./components/fonts/HacenLinerScreen.ttf);
}
@font-face {
  font-family: "Hacen Liner XL" ;
  src: url(./components/fonts/HacenLinerXL.ttf);
}
@font-face {
  font-family: "Hacen Liner XXL" ;
  src: url(./components/fonts/HacenLinerXXL.ttf);
}

.font{
  font-family: 'Cairo', sans-serif;
  /*font-family: "Hacen Liner Screen" ;*/
}

/* gallery viwer  */
.pswp-thumbnail {
  display: inline-block;
  
  margin: 5px 5px 5px 5px;
  cursor: pointer;
  
}
.pswp-thumbnails {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;

  
}
/*-------end gallery viewer-----------*/

.container-box{
  display: flex;
  flex-direction: row;
height: auto;
font-family: "Hacen Liner Screen Bd"
;
}
.right-box{
  width: 100%;
 margin-left:50px;
}
.left-box{
  width:500px;
 }
 .listItemText   {
  font-family: "Raleway", sans-serif;
   font-size: 17px;
   color:white;
   transition: 0.3s;
   cursor: pointer;
  }
  .listItemText:hover  {
    font-family: "Raleway", sans-serif;
     font-size: 17px;
     transition: 0.3s;
     color:#f82249;
    }

 
 .headerTopSM{
   display:none !important
 }
 .headerTopLG{
  display:flex !important
 }
 .pubText{
   font-size: 18px;
   font-weight: bolder;
 }
 .ribText{
   font-size: 18px;
   font-weight: bold;
   display:flex;
   
 }
 .footerText{
  font-size: 16px;
  display:flex;
  flex-direction: row-reverse;
  
}
.contactText{
  font-size: 20px;
 }

 .mainHeaderText11{
  font-size: 60px;
 }

 .mainHeaderText1{
  font-size: 40px;
 }
 .mainHeaderText2{
  font-size: 25px;
 }
 .imgXS{
  margin: 20px;
}
.container-video{
padding-left: 100px;  
padding-right: 100px;  

}

.login-bgImage{
  height:"100%" ;
  width:"100%";
  background-image:url("./assets/bg2.png");
  background-color: "#D7DBDD";
  background-position: "center";
  background-repeat: "no-repeat";
  background-size: "cover";
  position: "relative"
}

.boxLayout{
  padding-top:40px;
  padding-bottom: 40px;
}

 @media screen and (max-width :470px){
  .ribText{
    font-size: 12px;
    font-weight: bold;
    display:flex;
    flex-direction:column;
    align-items: center;
    
  }
  .pubText{
    font-size: 12px;
    font-weight: bolder;
  }
  .footerText{
    font-size: 14px;
    display:flex;
    flex-direction:column;
    align-items: center;
    
  }
  .listItemText {
    font-family: "Raleway", sans-serif;
    font-size: 14px;
  }
  .contactText{
    font-size: 15px;
   }
   .mainHeaderText11{
    font-size: 40px;
   }
   .mainHeaderText1{
    font-size: 30px;
   }
   .mainHeaderText2{
    font-size: 20px;
   }
 }
 

@media screen and (max-width :768px){
  .contactText{
    font-size: 18px;
   }
  .headerTopSM{
    display:flex !important
  }
  .listItemText {
    font-family: "Raleway", sans-serif;
    font-size: 17px;
  }
  .headerTopLG{
    display:none !important
   }
  .right-box{
    width: 100%;
    margin-left:0px;
  }
  .left-box{
    width: 100%;
    
  }
  .container-box{
    display: flex;
    flex-direction: column-reverse;
  }
  .container-boxImages{
    display: flex;
    flex-direction: column-reverse;
  }
  .imgXS{
    margin-top: 60px ;
  }
  .container-video{
    padding-left: 0px;  
    padding-right: 0px;  
    
    }
    
}

.zoom {
	-webkit-transform: scale(1);
	transform: scale(1);
	-webkit-transition: .3s ease-in-out;
	transition: .3s ease-in-out;
}
.zoom:hover {
	-webkit-transform: scale(1.3);
	transform: scale(1.3);
}

.navItem {
  color:rgba(202, 206, 221, 0.8)
}

.navItem a{
  color: #060c22;
 
  transition: 0.3s;
}
.navItem:hover>a{
  border-bottom:2px solid #f82249;
  color: #fff;
} 

 .navItem {
  display: inline-block;
  color: #000;
  text-decoration: none;
}

.navItem::after {
  content: '';
  display: block;
  width: 0;
  height: 2px;
  background: #000;
  transition: width .3s;
}

.navItem:hover::after {
  width: 100%;
  
}

.fmmLogo{
  width:180px;
  height:75px;
  margin-right:40px
}
.vpLogo{
  width:100px;
  height:130px;
  margin-left:40px
}


#intro {
  width: 100%;
  height: 130vh;
  background: url(./components/imges/intro-bg.jpg) top center;
  background-size: cover;
  overflow: hidden;
  position: relative;
}

#intro:before {
  content: "";
  background: rgba(6, 12, 34, 0.6);
  position: absolute;
  bottom: 0;
  top: 0;
  left: 0;
  right: 0;
}

#intro .intro-container {
  position: absolute;
  bottom: 0;
  left: 0;
  top: 90px;
  right: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  text-align: center;
  padding: 0 15px;
}


#intro h1 {
  color: #fff;
  font-family: "Raleway", sans-serif;
  font-size: 56px;
  font-weight: 600;
  text-transform: uppercase;
}

#intro h1 span {
  color: #f82249;
  text-shadow: 0px 0px 5px rgb(48, 47, 47);
}
#intro h1 b {
  font-size: 75px;
}

#intro h1 sup {
  font-size: 40px;
  text-transform: lowercase;
}

#intro p {
  color: #ebebeb;
  font-weight: 700;
  font-size: 26px;
}

#intro .play-btn {
  width: 94px;
  height: 94px;
  background: radial-gradient(#f82249 50%, rgba(101, 111, 150, 0.15) 52%);
  border-radius: 50%;
  display: block;
  position: relative;
  overflow: hidden;
}

#intro .play-btn::after {
  content: '';
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translateX(-40%) translateY(-50%);
  transform: translateX(-40%) translateY(-50%);
  width: 0;
  height: 0;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  border-left: 15px solid #fff;
  z-index: 100;
  transition: all 400ms cubic-bezier(0.55, 0.055, 0.675, 0.19);
}

#intro .play-btn:before {
  content: '';
  position: absolute;
  width: 120px;
  height: 120px;
  -webkit-animation-delay: 0s;
  animation-delay: 0s;
  -webkit-animation: pulsate-btn 2s;
  animation: pulsate-btn 2s;
  -webkit-animation-direction: forwards;
  animation-direction: forwards;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  -webkit-animation-timing-function: steps;
  animation-timing-function: steps;
  opacity: 1;
  border-radius: 50%;
  border: 2px solid rgba(163, 163, 163, 0.4);
  top: -15%;
  left: -15%;
  background: rgba(198, 16, 0, 0);
}

#intro .play-btn:hover::after {
  border-left: 15px solid #f82249;
  -webkit-transform: scale(20);
  transform: scale(20);
}

#intro .play-btn:hover::before {
  content: '';
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translateX(-40%) translateY(-50%);
  transform: translateX(-40%) translateY(-50%);
  width: 0;
  height: 0;
  border: none;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  border-left: 15px solid #fff;
  z-index: 200;
  -webkit-animation: none;
  animation: none;
  border-radius: 0;
}

#intro .about-btn {
  font-family: "Raleway", sans-serif;
  font-weight: 500;
  font-size: 14px;
  letter-spacing: 1px;
  display: inline-block;
  padding: 12px 32px;
  border-radius: 50px;
  transition: 0.5s;
  line-height: 1;
  margin: 10px;
  color: #fff;
  -webkit-animation-delay: 0.8s;
  animation-delay: 0.8s;
  border: 2px solid #f82249;
  cursor: pointer;
}

#intro .about-btn:hover {
  background: #f82249;
  color: #fff;
  cursor: pointer;
}

@-webkit-keyframes pulsate-btn {
  0% {
    -webkit-transform: scale(0.6, 0.6);
    transform: scale(0.6, 0.6);
    opacity: 1;
  }

  100% {
    -webkit-transform: scale(1, 1);
    transform: scale(1, 1);
    opacity: 0;
  }
}

@keyframes pulsate-btn {
  0% {
    -webkit-transform: scale(0.6, 0.6);
    transform: scale(0.6, 0.6);
    opacity: 1;
  }

  100% {
    -webkit-transform: scale(1, 1);
    transform: scale(1, 1);
    opacity: 0;
  }
}

/*--------------------------------------------------------------
# About Section
--------------------------------------------------------------*/

#about {
  background: url("./components/imges/about-bg.jpg");
  background-size: cover;
  overflow: hidden;
  position: relative;
  color: #fff;
  padding: 60px 0 40px 0;
}

#about:before {
  content: "";
  background: rgba(13, 20, 41, 0.8);
  position: absolute;
  bottom: 0;
  top: 0;
  left: 0;
  right: 0;
}

#about h2 {
  font-size: 36px;
  font-weight: bold;
  margin-bottom: 10px;
  color: #fff;
}

#about h3 {
  font-size: 18px;
  font-weight: bold;
  text-transform: uppercase;
  margin-bottom: 10px;
  color: #fff;
}

#about p {
  font-size: 14px;
  margin-bottom: 20px;
  color: #fff;
}

/* Sections Header
--------------------------------*/

.section-header {
  margin-bottom: 60px;
  position: relative;
  padding-bottom: 20px;
}

.section-header::before {
  content: '';
  position: absolute;
  display: block;
  width: 60px;
  height: 5px;
  background: #f82249;
  bottom: 0;
  left: calc(50% - 25px);
}

.section-header h2 {
  font-size: 36px;
  text-transform: uppercase;
  text-align: center;
  font-weight: 700;
  margin-bottom: 10px;
}

.section-header p {
  text-align: center;
  padding: 0;
  margin: 0;
  font-size: 18px;
  font-weight: 500;
  color: #9195a2;
}

.section-with-bg {
  background-color: #f6f7fd;
}

/*--------------------------------------------------------------
# Speakers Section
--------------------------------------------------------------*/

#speakers {
  padding: 60px 0 30px 0;
}

#speakers .speaker {
  position: relative;
  overflow: hidden;
  margin-bottom: 30px;
}

#speakers .speaker .details {
  background: rgba(6, 12, 34, 0.76);
  position: absolute;
  left: 0;
  bottom: -30px;
  right: 0;
  text-align: center;
  padding-top: 10px;
  transition: all 300ms cubic-bezier(0.645, 0.045, 0.355, 1);
}

#speakers .speaker .details h3 {
  color: #fff;
  font-size: 22px;
  font-weight: 600;
  margin-bottom: 5px;
}

#speakers .speaker .details p {
  color: #fff;
  font-size: 15px;
  margin-bottom: 10px;
  font-style: italic;
}

#speakers .speaker .details .social {
  height: 30px;
}

#speakers .speaker .details a {
  color: #fff;
}

#speakers .speaker .details a:hover {
  color: #f82249;
}

#speakers .speaker:hover .details {
  bottom: 0;
}

#speakers-details {
  padding: 60px 0;
}

#speakers-details .details h2 {
  color: #112363;
  font-size: 28px;
  font-weight: 700;
  margin-bottom: 10px;
}

#speakers-details .details .social {
  margin-bottom: 15px;
}

#speakers-details .details .social a {
  background: #e9edfb;
  color: #112363;
  line-height: 1;
  display: inline-block;
  text-align: center;
  border-radius: 50%;
  width: 36px;
  height: 36px;
  padding-top: 9px;
}

#speakers-details .details .social a:hover {
  background: #f82249;
  color: #fff;
}

#speakers-details .details .social a i {
  font-size: 18px;
}

#speakers-details .details p {
  color: #112363;
  font-size: 15px;
  margin-bottom: 10px;
}

/*--------------------------------------------------------------
# Schedule Section
--------------------------------------------------------------*/

#schedule {
  padding: 60px 0 60px 0;
}

#schedule .nav-tabs {
  text-align: center;
  margin: auto;
  display: block;
  border-bottom: 0;
  margin-bottom: 30px;
}

#schedule .nav-tabs li {
  display: inline-block;
  margin-bottom: 0;
}

#schedule .nav-tabs a {
  border: none;
  border-radius: 50px;
  font-weight: 600;
  background-color: #0e1b4d;
  color: #fff;
  padding: 10px 100px;
}

#schedule .nav-tabs a.active {
  background-color: #f82249;
  color: #fff;
}

#schedule .sub-heading {
  text-align: center;
  font-size: 18px;
  font-style: italic;
  margin: 0 auto 30px auto;
}

#schedule .tab-pane {
  transition: ease-in-out .2s;
}

#schedule .schedule-item {
  border-bottom: 1px solid #cad4f6;
  padding-top: 15px;
  padding-bottom: 15px;
  transition: background-color ease-in-out 0.3s;
}

#schedule .schedule-item:hover {
  background-color: #fff;
}

#schedule .schedule-item time {
  padding-bottom: 5px;
  display: inline-block;
}

#schedule .schedule-item .speaker {
  width: 60px;
  height: 60px;
  overflow: hidden;
  border-radius: 50%;
  float: left;
  margin: 0 10px 10px 0;
}

#schedule .schedule-item .speaker img {
  height: 100%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  margin-left: 50%;
  transition: all ease-in-out 0.3s;
}

#schedule .schedule-item h4 {
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 5px;
}

#schedule .schedule-item h4 span {
  font-style: italic;
  color: #19328e;
  font-weight: normal;
  font-size: 16px;
}

#schedule .schedule-item p {
  font-style: italic;
  color: #152b79;
  margin-bottom: 0;
}

/*--------------------------------------------------------------
# Venue Section
--------------------------------------------------------------*/

#venue {
  padding: 60px 0;
}

#venue .container-fluid {
  margin-bottom: 3px;
}

#venue .venue-map iframe {
  width: 100%;
  height: 100%;
  min-height: 300px;
}

#venue .venue-info {
  background: url("./components/imges/venue-info-bg.jpg") top center no-repeat;
  background-size: cover;
  position: relative;
  padding-top: 60px;
  padding-bottom: 60px;
}

#venue .venue-info:before {
  content: "";
  background: rgba(13, 20, 41, 0.8);
  position: absolute;
  bottom: 0;
  top: 0;
  left: 0;
  right: 0;
}

#venue .venue-info h3 {
  font-size: 36px;
  font-weight: 700;
  color: #fff;
}

#venue .venue-info p {
  color: #fff;
  margin-bottom: 0;
}

#venue .venue-gallery-container {
  padding-right: 12px;
}

#venue .venue-gallery {
  overflow: hidden;
  border-right: 3px solid #fff;
  border-bottom: 3px solid #fff;
}

#venue .venue-gallery img {
  transition: all ease-in-out 0.4s;
}

#venue .venue-gallery:hover img {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}

/*--------------------------------------------------------------
# Hotels Section
--------------------------------------------------------------*/

#hotels {
  padding: 60px 0;
}

#hotels .hotel {
  border: 1px solid #e0e5fa;
  background: #fff;
  margin-bottom: 30px;
}

#hotels .hotel:hover .hotel-img img {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}

#hotels .hotel-img {
  overflow: hidden;
  margin-bottom: 15px;
}

#hotels .hotel-img img {
  transition: 0.3s ease-in-out;
}

#hotels h3 {
  font-weight: 600;
  font-size: 20px;
  margin-bottom: 5px;
  padding: 0 20px;
}

#hotels a {
  color: #152b79;
}

#hotels a:hover {
  color: #f82249;
}

#hotels .stars {
  padding: 0 20px;
  margin-bottom: 5px;
}

#hotels .stars i {
  color: #ffc31d;
}

#hotels p {
  padding: 0 20px;
  margin-bottom: 20px;
  color: #060c22;
  font-style: italic;
  font-size: 15px;
}

/*--------------------------------------------------------------
# Gallery Section
--------------------------------------------------------------*/

#gallery {
  padding: 60px;
  overflow: hidden;
}

#gallery .owl-nav,
#gallery .owl-dots {
  margin-top: 25px;
  text-align: center;
}

#gallery .owl-item {
  border-left: 2px solid #fff;
  border-right: 2px solid #fff;
}

#gallery .owl-dot {
  display: inline-block;
  margin: 0 5px;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background-color: #ddd;
}

#gallery .owl-dot.active {
  background-color: #f82249;
}

#gallery .gallery-carousel .owl-stage-outer {
  overflow: visible;
}

#gallery .gallery-carousel .center {
  border: 6px solid #f82249;
  margin: -10px;
  box-sizing: content-box;
  padding: 4px;
  background: #fff;
  z-index: 1;
}

/*--------------------------------------------------------------
# Sponsors Section
--------------------------------------------------------------*/

#sponsors {
  padding: 60px 0;
}

#sponsors .sponsors-wrap {
  border-top: 1px solid #e0e5fa;
  border-left: 1px solid #e0e5fa;
  margin-bottom: 30px;
}

#sponsors .sponsor-logo {
  padding: 30px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  border-right: 1px solid #e0e5fa;
  border-bottom: 1px solid #e0e5fa;
  overflow: hidden;
  background: rgba(255, 255, 255, 0.5);
  height: 160px;
}

#sponsors .sponsor-logo:hover img {
  -webkit-transform: scale(1.2);
  transform: scale(1.2);
}

#sponsors img {
  transition: all 0.4s ease-in-out;
}

/*--------------------------------------------------------------
# F.A.Q Section
--------------------------------------------------------------*/

#faq {
  padding: 60px 0;
}

#faq #faq-list {
  padding: 0;
  list-style: none;
}

#faq #faq-list li {
  border-bottom: 1px solid #ddd;
}

#faq #faq-list a {
  padding: 18px 0;
  display: block;
  position: relative;
  font-family: "Raleway", sans-serif;
  font-size: 16px;
  line-height: 24px;
  font-weight: 600;
  padding-right: 20px;
}

#faq #faq-list i {
  font-size: 24px;
  position: absolute;
  right: 0;
  top: 16px;
}

#faq #faq-list p {
  margin-bottom: 20px;
}

#faq #faq-list a.collapse {
  color: #f82249;
}

#faq #faq-list a.collapsed {
  color: #000;
}

#faq #faq-list a.collapsed i::before {
  content: "\f055" !important;
}

/*--------------------------------------------------------------
# Subscribe Section
--------------------------------------------------------------*/

#subscribe {
  padding: 60px;
  background: url(./components/imges/subscribe-bg.jpg) center center no-repeat;
  background-size: cover;
  overflow: hidden;
  position: relative;
}

#subscribe:before {
  content: "";
  background: rgba(6, 12, 34, 0.6);
  position: absolute;
  bottom: 0;
  top: 0;
  left: 0;
  right: 0;
}

#subscribe .section-header h2,
#subscribe p {
  color: #fff;
}

#subscribe input {
  background: #fff;
  color: #060c22;
  border: 0;
  outline: none;
  margin: 0;
  padding: 9px 20px;
  border-radius: 50px;
  font-size: 14px;
}

#subscribe button {
  border: 0;
  padding: 9px 25px;
  cursor: pointer;
  background: #f82249;
  color: #fff;
  transition: all 0.3s ease;
  outline: none;
  font-size: 14px;
  border-radius: 50px;
}

#subscribe button:hover {
  background: #e0072f;
}

/*--------------------------------------------------------------
# Buy Tickets Section
--------------------------------------------------------------*/

#buy-tickets {
  padding: 60px 0;
}

#buy-tickets .card {
  border: none;
  border-radius: 5px;
  transition: all  0.3s ease-in-out;
  box-shadow: 0 10px 25px 0 rgba(6, 12, 34, 0.1);
}

#buy-tickets .card:hover {
  box-shadow: 0 10px 35px 0 rgba(6, 12, 34, 0.2);
}

#buy-tickets .card hr {
  margin: 25px 0;
}

#buy-tickets .card .card-title {
  margin: 10px 0;
  font-size: 14px;
  letter-spacing: 1px;
  font-weight: bold;
}

#buy-tickets .card .card-price {
  font-size: 48px;
  margin: 0;
}

#buy-tickets .card ul li {
  margin-bottom: 20px;
}

#buy-tickets .card .text-muted {
  opacity: 0.7;
}

#buy-tickets .card .btn {
  font-size: 15px;
  border-radius: 50px;
  padding: 10px 40px;
  transition: all 0.2s;
  background-color: #f82249;
  border: 0;
  color: #fff;
}

#buy-tickets .card .btn:hover {
  background-color: #e0072f;
}

#buy-tickets #buy-ticket-modal input,
#buy-tickets #buy-ticket-modal select {
  border-radius: 0;
}

#buy-tickets #buy-ticket-modal .btn {
  font-size: 15px;
  border-radius: 50px;
  padding: 10px 40px;
  transition: all 0.2s;
  background-color: #f82249;
  border: 0;
  color: #fff;
}

#buy-tickets #buy-ticket-modal .btn:hover {
  background-color: #e0072f;
}

/*--------------------------------------------------------------
# Contact Section
--------------------------------------------------------------*/

#contact {
  padding: 60px 0;
}

#contact .contact-info {
  margin-bottom: 20px;
  text-align: center;
}

#contact .contact-info i {
  font-size: 48px;
  display: inline-block;
  margin-bottom: 10px;
  color: #f82249;
}

#contact .contact-info address,
#contact .contact-info p {
  margin-bottom: 0;
  color: #112363;
}

#contact .contact-info h3 {
  font-size: 18px;
  margin-bottom: 15px;
  font-weight: bold;
  text-transform: uppercase;
  color: #112363;
}

#contact .contact-info a {
  color: #4869df;
}

#contact .contact-info a:hover {
  color: #f82249;
}

#contact .contact-address,
#contact .contact-phone,
#contact .contact-email {
  margin-bottom: 20px;
}

#contact .form #sendmessage {
  color: #f82249;
  border: 1px solid #f82249;
  display: none;
  text-align: center;
  padding: 15px;
  font-weight: 600;
  margin-bottom: 15px;
}

#contact .form #errormessage {
  color: red;
  display: none;
  border: 1px solid red;
  text-align: center;
  padding: 15px;
  font-weight: 600;
  margin-bottom: 15px;
}

#contact .form #sendmessage.show,
#contact .form #errormessage.show,
#contact .form .show {
  display: block;
}

#contact .form .validation {
  color: red;
  display: none;
  margin: 0 0 20px;
  font-weight: 400;
  font-size: 13px;
}

#contact .form input,
#contact .form textarea {
  padding: 10px 14px;
  border-radius: 0;
  box-shadow: none;
  font-size: 15px;
}

#contact .form button[type="submit"] {
  background: #f82249;
  border: 0;
  padding: 10px 40px;
  color: #fff;
  transition: 0.4s;
  border-radius: 50px;
  cursor: pointer;
}

#contact .form button[type="submit"]:hover {
  background: #e0072f;
}

/*--------------------------------------------------------------
# Footer
--------------------------------------------------------------*/

#footer {
  background: #101522;
  padding: 0 0 25px 0;
  color: #eee;
  font-size: 14px;
}

#footer .footer-top {
  background: #040919;
  padding: 60px 0 30px 0;
}

#footer .footer-top .footer-info {
  margin-bottom: 30px;
}

#footer .footer-top .footer-info h3 {
  font-size: 26px;
  margin: 0 0 20px 0;
  padding: 2px 0 2px 0;
  line-height: 1;
  font-family: "Raleway", sans-serif;
  font-weight: 700;
  color: #fff;
}

#footer .footer-top .footer-info img {
  height: 40px;
  margin-bottom: 10px;
}

#footer .footer-top .footer-info p {
  font-size: 14px;
  line-height: 24px;
  margin-bottom: 0;
  font-family: "Raleway", sans-serif;
  color: #fff;
}

#footer .footer-top .social-links a {
  font-size: 18px;
  display: inline-block;
  background: #222636;
  color: #eee;
  line-height: 1;
  padding: 8px 0;
  margin-right: 4px;
  border-radius: 50%;
  text-align: center;
  width: 36px;
  height: 36px;
  transition: 0.3s;
}

#footer .footer-top .social-links a:hover {
  background: #f82249;
  color: #fff;
}

#footer .footer-top h4 {
  font-size: 14px;
  font-weight: bold;
  color: #fff;
  text-transform: uppercase;
  position: relative;
  padding-bottom: 12px;
  border-bottom: 2px solid #f82249;
}

#footer .footer-top .footer-links {
  margin-bottom: 30px;
}

#footer .footer-top .footer-links ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

#footer .footer-top .footer-links ul i {
  padding-right: 5px;
  color: #f82249;
  font-size: 18px;
}

#footer .footer-top .footer-links ul li {
  border-bottom: 1px solid #262c44;
  padding: 10px 0;
}

#footer .footer-top .footer-links ul li:first-child {
  padding-top: 0;
}

#footer .footer-top .footer-links ul a {
  color: #eee;
}

#footer .footer-top .footer-links ul a:hover {
  color: #f82249;
}

#footer .footer-top .footer-contact {
  margin-bottom: 30px;
}

#footer .footer-top .footer-contact p {
  line-height: 26px;
}

#footer .footer-top .footer-newsletter {
  margin-bottom: 30px;
}

#footer .footer-top .footer-newsletter input[type="email"] {
  border: 0;
  padding: 6px 8px;
  width: 65%;
}

#footer .footer-top .footer-newsletter input[type="submit"] {
  background: #f82249;
  border: 0;
  width: 35%;
  padding: 6px 0;
  text-align: center;
  color: #fff;
  transition: 0.3s;
  cursor: pointer;
}

#footer .footer-top .footer-newsletter input[type="submit"]:hover {
  background: #e0072f;
}

#footer .copyright {
  text-align: center;
  padding-top: 30px;
}

#footer .credits {
  text-align: center;
  font-size: 13px;
  color: #ddd;
}


/*--------------------------------------------------------------
# Responsive Media Queries
--------------------------------------------------------------*/

@media (min-width: 767px) {
  #subscribe input {
    min-width: 400px;
  }
}

@media (min-width: 768px) {
  #contact .contact-address,
  #contact .contact-phone,
  #contact .contact-email {
    padding: 20px 0;
  }

  #contact .contact-phone {
    border-left: 1px solid #ddd;
    border-right: 1px solid #ddd;
  }
}

@media (min-width: 991px) {
  #schedule .sub-heading {
    width: 75%;
  }
}

@media (min-width: 1024px) {
  #intro {
    background-attachment: fixed;
    height: 130vh;
  }

  #about {
    background-attachment: fixed;
  }

  #subscribe {
    background-attachment: fixed;
  }
}

@media (max-width: 1199px) {
  #header .container {
    max-width: 100%;
  }

  .nav-menu a {
    padding: 8px 4px;
  }
}

@media (max-width: 991px) {
  #header {
    background: rgba(6, 12, 34, 0.98);
    height: 70px;
    padding: 15px 0;
    transition: all 0.5s;
  }

  #nav-menu-container {
    display: none;
  }

  #mobile-nav-toggle {
    display: inline;
  }

  #intro .intro-container {
    top: 70px;
  }

  #intro h1 {
    font-size: 34px;
  }

  #intro h1 b {
    font-size: 50px;
  }
  
  #intro h1 sup {
    font-size: 25px;
    text-transform: lowercase;
  }

  #intro p {
    font-size: 20px;
  }

  #schedule .nav-tabs a {
    padding: 8px 60px;
  }
}

@media (max-width: 768px) {
  .back-to-top {
    bottom: 15px;
  }

  #faq #faq-list a {
    font-size: 18px;
  }

  #faq #faq-list i {
    top: 13px;
  }

}

@media (max-width: 767px) {
  #schedule .nav-tabs a {
    padding: 8px 50px;
  }
}

@media (max-width: 574px) {
  #venue .venue-info h3 {
    font-size: 24px;
  }
  .fmmLogo{
    width:150px;
    height:65px;
    margin-right:20px
  }
  .vpLogo{
    width:90px;
    height:110px;
    margin-left:20px
  }
  #intro {
    
    height: 100vh;
  
  }
  #intro .intro-container {
    
    top: 0px;
   
  }
  
}

@media (max-width: 480px) {
  #schedule .nav-tabs a {
    padding: 8px 30px;
  }
}

@media (max-width: 460px) {
  #subscribe button {
    margin-top: 10px;
  }
}


@media (max-width: 300px) {
  .fmmLogo{
    width:150px;
    height:65px;
    margin-right:0px;
    margin-bottom:20px;
  }
  .vpLogo{
    width:90px;
    height:110px;
    margin-left:0px;
  }
  #intro {
    
    height: 130vh;
  
  }
  .logo-container{
    display:flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  #intro .intro-container {
    
    top: 0px;
   
  }
}

